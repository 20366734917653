import React, { useEffect, useState } from "react"

export const withPopup = Component => ({ name = "Popup", active, setActive, clickedSlideIndex, media }) => {
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    if (active && swiper !== null) {
      swiper.slideTo(clickedSlideIndex, 0)
    }
  }, [clickedSlideIndex, active, swiper])

  Component.displayName = name
  return <Component active={active} setActive={setActive} setSwiper={setSwiper} media={media} />
}
