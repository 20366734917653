import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withInstagram = Component => ({ name = "Instagram" }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_INSTAGRAM {
      productTemplateData: sanityTemplateProduct {
        settingInstagramLink: _rawSettingInstagramLink(resolveReferences: { maxDepth: 2 })
        additionalHighlightWords
        additionalInstagramFeedTitle
      }
    }
  `)

  const { settingInstagramLink, additionalHighlightWords, additionalInstagramFeedTitle } = productTemplateData || {}

  Component.displayName = name
  return <Component title={additionalInstagramFeedTitle} link={settingInstagramLink} additionalHighlightWords={additionalHighlightWords} />
}
