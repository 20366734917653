import React from "react"

import { withDescription } from "./withDescription"
import { Wrapper, RichText } from "./DescriptionStyles"
import { Subtitle as Title } from "../ProductStyles"

export const Description = withDescription(({ additionalProductDescriptionText, description }) => (
  <Wrapper>
    <Title>{additionalProductDescriptionText}</Title>
    <RichText dangerouslySetInnerHTML={{ __html: description }} />
  </Wrapper>
))
