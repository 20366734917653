import React from "react"

import { withInstagram } from "./withInstagram"
import { Instagram as InstagramFeed } from "../../Sections/Instagram/Instagram"
import { Wrapper } from "./InstagramStyles"

export const Instagram = withInstagram(({ title, link, additionalHighlightWords }) => (
  <Wrapper>
    <InstagramFeed title={title} link={link} additionalHighlightWords={additionalHighlightWords} />
  </Wrapper>
))
