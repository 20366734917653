import tw, { styled } from "twin.macro"

export const Wrapper = tw.ul`
  flex flex-wrap items-start justify-between w-full pb-2-4
`

export const Point = styled.div`
  ${tw`flex flex-row items-start justify-center border-r border-black border-opacity-50 w-1/2`}
  :last-of-type {
    ${tw`border-0`}
  }
  span {
    svg {
      width: 75%;
      height: 75%;
    }
  }
`

export const Text = tw.div`
  ml-0-8 text-14 leading-1.42 min-w-11 max-w-11
`
