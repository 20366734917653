import React from "react"
import { Link } from "gatsby"

import { withSellingPoints } from "./withSellingPoints"
import { Wrapper, Point, Text } from "./SellingPointsStyles"
import { Icon } from "../../Icon/Icon"

export const SellingPoints = withSellingPoints(({ points }) =>
  points?.length > 0 ? (
    <Wrapper>
      {points?.map(({ _key, link, icon }) => (
        <Point key={_key} as={link?.url ? Link : null} to={link?.url}>
          <div className={"max-w-3-6"}>
            <Icon name={icon} />
          </div>
          <Text>{link.title}</Text>
        </Point>
      ))}
    </Wrapper>
  ) : null
)
