import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withMobileImages } from "./withMobileImages"
import { Wrapper, Prev, Next, PlaceHolder } from "./MobileImagesStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Video } from "../../../Video/Video"

SwiperCore.use([Navigation])

export const MobileImages = withMobileImages(({ image, loading, media }) => (
  <Wrapper>
    <Prev id={"mobile-prev"} title={"previous image"}>
      <Icon name={"chevronLeft"} width={8} height={14} />
    </Prev>
    <Next id={"mobile-next"} title={"next image"}>
      <Icon name={"chevronRight"} width={8} height={14} />
    </Next>
    {loading ? (
      <Image image={image} ratio={"1/1"} />
    ) : (
      <Swiper
        navigation={{
          prevEl: "#mobile-prev",
          nextEl: "#mobile-next",
        }}
        loop={true}
        lazy={true}
        preloadImages={false}
      >
        {media.length > 0 ? (
          media.map(item => (
            <SwiperSlide key={item?.id}>
              {item?.image?.src ? (
                <Image image={item?.image} ratio={"1/1"} />
              ) : (
                <Video url={item?.sources?.[2]?.url} image={item?.previewImage} ratio={"1/1"} loop />
              )}
            </SwiperSlide>
          ))
        ) : (
          <PlaceHolder />
        )}
      </Swiper>
    )}
  </Wrapper>
))
