import React from "react"

import { withProduct } from "./withProduct"
import {
  Outer,
  Inner,
  ImagesWrapper,
  DetailsWrapper,
  Sticky,
  TitleWrapper,
  Title,
  PriceWrapper,
  BadgeWrapper,
  Divider,
  VariantsWrapper,
} from "./ProductStyles"
import { Images } from "./Images/Images"
import { Wishlist } from "./Wishlist/Wishlist"
import { Price } from "./Price/Price"
import { Badge } from "./Badge/Badge"
import { Message } from "./Message/Message"
import { AdditionalPayments } from "./AdditionalPayments/AdditionalPayments"
import { Swatches } from "./Swatches/Swatches"
import { Variants } from "./Variants/Variants"
import { SizeGuide } from "./SizeGuide/SizeGuide"
import { AddToCart } from "./AddToCart/AddToCart"
import { Description } from "./Description/Description"
import { Features } from "./Features/Features"
import { MoreInformation } from "./MoreInformation/MoreInformation"
import { SellingPoints } from "./SellingPoints/SellingPoints"
import { Related } from "./Related/Related"
import { Instagram } from "./Instagram/Instagram"
import { Popup as SizeGuidePopup } from "./SizeGuide/Popup/Popup"
import { StarRating } from "../OkendoReviews/StarRating/StarRating"
import { ReviewsWidget } from "../OkendoReviews/ReviewsWidget/ReviewsWidget"

export const Product = withProduct(
  ({
    product: { title, handle, descriptionHtml, tags, image, metafields },
    spec,
    product,
    media,
    siblings,
    loading,
    handleVariant,
    selectedOptions,
    selectedVariant,
    defaultVariant,
    showReviews,
    multipleSiblings,
    multipleVariants,
    settingShowInstagramFeed,
    settingShowRelatedProducts,
    sizeConversions,
    relatedProducts,
    relatedCollection,
    recommendations,
    sizeGuideActive,
    setSizeGuideActive,
    hideGender,
    gender,
    productId,
  }): JSX.Element => {
    return (
      <Outer>
        <Inner>
          <ImagesWrapper>
            <Images image={image} media={media} loading={loading} />
          </ImagesWrapper>
          <DetailsWrapper>
            <Sticky>
              {multipleSiblings ? (
                <>
                  <Swatches
                    siblings={siblings}
                    selectOptions={selectedOptions}
                    defaultSelectedOption={defaultVariant?.selectedOptions}
                    handle={handle}
                    layout={"mobile"}
                  />
                </>
              ) : null}
              <TitleWrapper>
                <Title>{title}</Title>
                <Wishlist product={product} selectedVariant={selectedVariant} />
              </TitleWrapper>
              <PriceWrapper>
                <Price variant={selectedVariant || defaultVariant} layout={"product"} />
              </PriceWrapper>
              {/* Note: temporarily hide afterpay or additional payments */}
              {/* <AdditionalPayments variant={selectedVariant || defaultVariant} /> */}
              <BadgeWrapper>
                <Badge tags={tags} layout={"product"} />
                <Message tags={tags} layout={"product"} />
              </BadgeWrapper>
              {showReviews && <StarRating productId={productId} />}
              {!loading ? (
                <>
                  {multipleSiblings ? (
                    <>
                      <Swatches
                        siblings={siblings}
                        selectOptions={selectedOptions}
                        defaultSelectedOption={defaultVariant?.selectedOptions}
                        handle={handle}
                        layout={"desktop"}
                      />
                    </>
                  ) : null}
                  {multipleVariants ? (
                    <VariantsWrapper>
                      <SizeGuide tags={tags} />
                      <Variants
                        handleVariant={handleVariant}
                        product={product}
                        hideGender={hideGender}
                        gender={gender}
                        selectedOptions={selectedOptions}
                        selectedVariant={selectedVariant}
                        sizeConversions={sizeConversions}
                      />
                    </VariantsWrapper>
                  ) : null}
                </>
              ) : null}
              {/* {selectedVariant && ( */}
              <AddToCart
                sizeConversions={sizeConversions}
                hideGender={hideGender}
                gender={gender}
                product={product}
                selectedVariant={selectedVariant}
                layout={"product"}
              />
              {/* )} */}
              <SellingPoints />
              <Description description={descriptionHtml} />
              <Features tags={tags} />
              <MoreInformation metafields={metafields} spec={spec} />
            </Sticky>
            {/* <SizeGuidePopup active={sizeGuideActive} setActive={setSizeGuideActive} /> */}
          </DetailsWrapper>
        </Inner>
        {showReviews && <ReviewsWidget productId={productId} />}
        <Divider />
        {settingShowRelatedProducts ? (
          <Related recommendations={recommendations} relatedProducts={relatedProducts} relatedCollection={relatedCollection} />
        ) : null}
        {settingShowInstagramFeed ? <Instagram /> : null}
      </Outer>
    )
  }
)
