import tw, { styled } from "twin.macro"

export const Outer = styled.div<OuterProps>`
  ${tw`flex-row`}
  ${({ layout }) => {
    if (layout === "mobile") return tw`flex md:hidden my-2-4`
    if (layout === "desktop") return tw`hidden md:flex my-2`
  }}
`

export const Inner = tw.div`flex flex-row`

export const Swatch = styled.button<SwatchProps>`
  ${tw`relative w-2-2 h-2-2 border border-transparent mr-1-6 rounded-full`}
  :before {
    content: "";
    ${({ active }) =>
      active === "true"
        ? tw`absolute top-0-3 left-0-7 w-0-6 h-1-2 border-white border-t-0 border-r-2 border-b-2 border-l-0 transform rotate-45`
        : null}
  }
`

export const Text = tw.p`
  text-14 leading-1.78
`

type OuterProps = {
  layout: "desktop" | "mobile"
}

type SwatchProps = {
  colour: string
  active: "true" | "false"
}
