import React from "react"
import { withFeatures } from "./withFeatures"
import { Wrapper } from "./FeaturesStyles"
import { Subtitle as Title } from "../ProductStyles"
import { Accordion } from "../../Accordion/Accordion"
import { RichText } from "../../RichText/RichText"

export const Features = withFeatures(({ additionalProductFeaturesText, features }) =>
  features.length > 0 ? (
    <Wrapper>
      <Title>{additionalProductFeaturesText}</Title>
      {features.map(feature => (
        <Accordion key={feature._key} layout={"features"} title={feature.title} prefixIcon={feature.icon}>
          <RichText spacing={"default"}>{feature.content}</RichText>
        </Accordion>
      ))}
    </Wrapper>
  ) : null
)
