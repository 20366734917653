import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`flex-1 pb-1-2 border-b  border-grey`

export const GridWrapper = tw.div`
  
`

export const Message = tw.div`block flex justify-between pt-1-2 pb-1-2 cursor-pointer uppercase font-bold`

export const MessageWrapper = tw.div`flex-grow`

export const IconOuter = tw.div`
  relative
`

export const IconInner = styled.div<VariantProps>`
  ${tw`absolute top-1/2 right-0 transform -translate-y-1/2`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible pointer-events-auto` : tw`opacity-0 invisible pointer-events-none`)}
`

export const Grid = tw.div`
  grid grid-cols-3 lg:grid-cols-4 gap-0-8 py-1-2
`

export const SizeButtonWrapper = styled.div`
  ${tw`flex justify-end gap-1`}
  pointer-events: all;
`

export const SizeButton = styled.button`
  ${tw`w-auto text-left uppercase border-b`}
  ${({ active }) => (active ? tw`border-black` : tw`border-transparent`)}
`

export const Variant = styled.button<VariantProps>`
  ${tw`border rounded-5 py-1`}
  ${({ active, outOfStock }) => {
    if (active === "false" && outOfStock) {
      return tw`opacity-30`
    } else if (active === "true" && outOfStock) {
      return tw`border-red bg-transparent text-red opacity-30`
    } else if (active === "true" && !outOfStock) {
      return tw`bg-black text-beige-light`
    } else {
      return tw`bg-transparent text-black`
    }
  }}
`

export const SizeWrapper = tw.div`
  flex flex-col gap-0-8
`

export const SizeTitle = tw.div`uppercase`

type VariantProps = {
  active: "true" | "false"
  nostock: "true" | "false"
}
