import React from "react"

import { withDesktopImages } from "./withDesktopImages"
import { Outer, Next, Prev, PlaceHolder, Inner, IconWrapper } from "./DesktopImagesStyles"
import { Image } from "../../../Image/Image"
import { Popup } from "../Popup/Popup"
import { Icon } from "../../../Icon/Icon"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Navigation])

export const DesktopImages = withDesktopImages(({ image, loading, handleImageClick, popupActive, setPopupActive, clickedSlideIndex, media }) => (
  <>
    <Outer>
      <Prev id={"mobile-prev"} title={"previous image"}>
        <Icon name={"chevronLeft"} />
      </Prev>
      <Next id={"mobile-next"} title={"next image"}>
        <Icon name={"chevronRight"} />
      </Next>
      {loading ? (
        <Image image={image} ratio={"1/1"} />
      ) : (
        <Swiper
          navigation={{
            prevEl: "#mobile-prev",
            nextEl: "#mobile-next",
          }}
          loop={true}
          lazy={true}
          preloadImages={false}
        >
          {media.length > 0 ? (
            media.map((item, index) => (
              <SwiperSlide key={item?.id}>
                {item?.image?.src ? (
                  <Inner key={item?.id}>
                    <Image handleClick={() => handleImageClick(index)} image={item?.image} ratio={"1/1"} />
                  </Inner>
                ) : (
                  <Inner key={item?.id}>
                    <IconWrapper onClick={() => handleImageClick(index)}>
                      <Icon name={"video"} />
                    </IconWrapper>
                    <Image image={item?.previewImage} ratio={`1/1`} />
                  </Inner>
                )}
              </SwiperSlide>
            ))
          ) : (
            <PlaceHolder />
          )}
        </Swiper>
      )}
    </Outer>
    <Popup active={popupActive} setActive={setPopupActive} media={media} clickedSlideIndex={clickedSlideIndex + 1} />
  </>
))
