import React from "react"

import { withFeaturedProducts } from "./withFeaturedProducts"
import { Card } from "../../Product/Card/Card"
import { FeaturedSlider } from "../../Slider/FeaturedSlider/FeaturedSlider"

export const FeaturedProducts = withFeaturedProducts(
  ({ title, link, items }): JSX.Element =>
    items.length > 0 ? (
      <FeaturedSlider
        id={"featured-product"}
        title={title}
        items={items}
        callToActionText={link.title}
        callToActionUrl={link.url}
        Card={Card}
        layout={"product"}
      />
    ) : null
)

export default FeaturedProducts
