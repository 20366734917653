import React from "react"

import { withImages } from "./withImages"
import { DesktopImages } from "./Desktop/DesktopImages"
import { MobileImages } from "./Mobile/MobileImages"

export const Images = withImages(({ image, loading, media }) => (
  <>
    <DesktopImages image={image} loading={loading} media={media} />
    <MobileImages image={image} loading={loading} media={media} />
  </>
))
