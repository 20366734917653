import React, { useCallback } from "react"

import { useApp } from "../../../hooks/useApp"

export const withSwatches = Component => ({ name = "Swatches", siblings, selectOptions = [], handle, layout, defaultSelectedOption }) => {
  const {
    config: {
      settings: {
        routes: { PRODUCT },
        tags: { swatchColor },
        constraints: { COLOUR },
      },
    },
  } = useApp()

  const getSwatchColour = useCallback(
    tags => {
      return tags.find(tag => tag.includes(swatchColor))?.split(":")?.[1]
    },
    [swatchColor]
  )

  const swatches = siblings?.map(({ id, title, tags, shopify, handle }) => ({
    id,
    title,
    handle: shopify?.shopifyHandle || handle,
    colour: getSwatchColour(tags),
    url: `${PRODUCT}/${shopify?.shopifyHandle || handle}`,
  }))

  const selectedColour =
    selectOptions?.find(option => option.name === COLOUR)?.value || defaultSelectedOption?.find(option => option.name === COLOUR)?.value

  Component.displayName = name
  return <Component swatches={swatches} colour={selectedColour} handle={handle} layout={layout} />
}
