import React from "react"
import { Link } from "gatsby"

import { withSwatches } from "./withSwatches"
import { Outer, Inner, Swatch, Text } from "./SwatchesStyles"

export const Swatches = withSwatches(({ swatches, handle: selectedHandle, colour, layout }) => (
  <Outer layout={layout}>
    <Inner>
      {swatches.map(({ id, title, handle, colour, url }) => (
        <Swatch
          key={id}
          as={Link}
          to={url}
          title={title}
          disabled={selectedHandle === handle}
          colour={colour}
          active={selectedHandle === handle ? "true" : "false"}
          style={{ background: `#${colour}` }}
        />
      ))}
    </Inner>
    {colour ? <Text>{colour}</Text> : null}
  </Outer>
))
