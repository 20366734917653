import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useImage } from "../../../hooks/useImage"

export const withFeatures = Component => ({ name = "Features", tags }) => {
  const {
    config: {
      settings: {
        tags: { feature },
      },
    },
  } = useApp()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_FEATURES {
      productTemplateData: sanityTemplateProduct {
        additionalProductFeaturesText
        settingProductFeatures: _rawSettingProductFeatures(resolveReferences: { maxDepth: 3 })
      }
    }
  `)

  const { additionalProductFeaturesText, settingProductFeatures } = productTemplateData || {}

  const { getFluidImage } = useImage()

  const featureTags = tags.filter(tag => tag.includes(feature))?.map(tag => tag.split(":")?.[1]?.toLowerCase())

  const getFeatures = useCallback((tags, features) => {
    const tempArray = []
    features.forEach(feature => {
      tags.forEach(tag => {
        if (feature.title.toLowerCase() === tag) {
          tempArray.push(feature)
        }
      })
    })
    return tempArray
  }, [])

  const features = getFeatures(featureTags, settingProductFeatures)?.map(feature => ({
    ...feature,
    icon: getFluidImage(feature.icon),
  }))

  Component.displayName = name
  return <Component additionalProductFeaturesText={additionalProductFeaturesText} features={features} />
}
