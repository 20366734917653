import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withRelated = Component => ({ name = "Related", recommendations, relatedProducts, relatedCollection }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_RELATED {
      productTemplateData: sanityTemplateProduct {
        settingRelatedProductsLink: _rawSettingRelatedProductsLink(resolveReferences: { maxDepth: 2 })
        additionalRelatedProductsTitle
      }
    }
  `)

  const { settingRelatedProductsLink, additionalRelatedProductsTitle } = productTemplateData || {}

  Component.displayName = name
  return (
    <Component
      title={additionalRelatedProductsTitle}
      link={settingRelatedProductsLink}
      recommendations={recommendations}
      relatedProducts={relatedProducts}
      relatedCollection={relatedCollection}
    />
  )
}
