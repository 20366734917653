import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"

export const withSellingPoints = Component => ({ name = "SellingPoints" }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_SELLING_POINTS {
      productTemplateData: sanityTemplateProduct {
        settingSellingPoints: _rawSettingSellingPoints(resolveReferences: { maxDepth: 3 })
      }
    }
  `)

  const { settingSellingPoints } = productTemplateData || {}

  const { linkResolver } = useRoutes()

  const points = settingSellingPoints?.map(point => ({
    ...point,
    link: linkResolver(point.link),
  }))

  Component.displayName = name
  return <Component points={points} />
}
