import React from "react"
import { SwiperSlide } from "swiper/react"

import { withInstagram } from "./withInstagram"
import { Wrapper, Panel, Title, InstagramLinkWrapper, InstagramLink, InstagramLinkSpan, Prev, Next } from "./InstagramStyles"
import { Slider } from "../../Slider/Slider"
import { Image } from "../../Image/Image"
import { Icon } from "../../Icon/Icon"

export const Instagram = withInstagram(
  ({ id = "instagram-swiper", items, title, url, instagramTitle, additionalHighlightWords }): JSX.Element => (
    <Wrapper>
      <Panel>
        <Title>{title}</Title>
        <InstagramLinkWrapper>
          <InstagramLink href={url} target={"_blank"} title={title}>
            <InstagramLinkSpan>{instagramTitle[0]}</InstagramLinkSpan>
            {additionalHighlightWords ? <InstagramLinkSpan highlight={"true"}>{additionalHighlightWords}</InstagramLinkSpan> : null}
            {instagramTitle.length > 1 ? <InstagramLinkSpan>{instagramTitle?.[1]}</InstagramLinkSpan> : null}
          </InstagramLink>
          <Prev id={`${id}-prev`}>
            <Icon name={"arrowLeft"} />
          </Prev>
          <Next id={`${id}-next`}>
            <Icon name={"arrow"} />
          </Next>
        </InstagramLinkWrapper>
      </Panel>
      <Slider layout={"instagram"} prevEl={`#${id}-prev`} nextEl={`#${id}-next`}>
        {items.map(item => (
          <SwiperSlide key={item.id}>
            <Image link={item.link} image={item.image} ratio={"1/1"} />
          </SwiperSlide>
        ))}
      </Slider>
    </Wrapper>
  )
)

export default Instagram
