import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withDescription = Component => ({ name = "Description", description }) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_DESCRIPTION {
      productTemplateData: sanityTemplateProduct {
        additionalProductDescriptionText
      }
    }
  `)

  const { additionalProductDescriptionText } = productTemplateData || {}

  Component.displayName = name
  return <Component description={description} additionalProductDescriptionText={additionalProductDescriptionText} />
}
