import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"

export const withMoreInformation = Component => ({ name = "MoreInformation", metafields, spec }) => {
  const {
    config: {
      settings: {
        constraints: { CARE },
      },
    },
  } = useApp()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_INFORMATION {
      productTemplateData: sanityTemplateProduct {
        additionalProductInformationText
        additionalProductSpecText
        additionalProductCareText
        settingMoreInformation: _rawSettingMoreInformation(resolveReferences: { maxDepth: 8 })
      }
    }
  `)

  const { additionalProductInformationText, additionalProductSpecText, additionalProductCareText, settingMoreInformation } = productTemplateData || {}

  const getMetafield = key => metafields?.find(metafield => metafield.key === key)?.value

  const productCare = getMetafield(CARE)

  Component.displayName = name
  return (
    <Component
      spec={spec}
      care={productCare}
      moreInfo={settingMoreInformation}
      additionalProductInformationText={additionalProductInformationText}
      additionalProductSpecText={additionalProductSpecText}
      additionalProductCareText={additionalProductCareText}
    />
  )
}
