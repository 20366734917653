import React from "react"

import { withVariants } from "./withVariants"
import { Wrapper, GridWrapper, Message, MessageWrapper, Grid, Variant, SizeButtonWrapper, SizeButton, SizeTitle, SizeWrapper } from "./VariantsStyles"
import { StyledSelectWrapper, StyledSelect, StyledSelectOption, StyledInputIconWrapper } from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"
import { AccordionAnimation } from "../../Accordion/AccordionAnimation"

export const Variants = withVariants(
  ({
    isOpen,
    handleVariant,
    options,
    selectedOptions,
    SIZE,
    additionalSizePrefix,
    additionalSizeSelectionText,
    isOutOfStock,
    getSize,
    sizeRegions,
    updateRegion,
    region,
  }) => {
    return options?.length > 0 ? (
      <Wrapper>
        {options?.map((option, index) => {
          if (option.name === SIZE) {
            return (
              <GridWrapper key={index}>
                <Message>
                  <MessageWrapper>
                    {selectedOptions.some(({ name }) => name === SIZE)
                      ? `${additionalSizePrefix}: ${getSize(selectedOptions.find(({ name }) => name === SIZE)?.value)}`
                      : additionalSizeSelectionText}
                  </MessageWrapper>
                  {sizeRegions?.length > 0 ? (
                    <SizeWrapper>
                      <SizeTitle>Region Selector</SizeTitle>
                      <SizeButtonWrapper>
                        {sizeRegions?.map(size => (
                          <SizeButton key={size} onClick={() => updateRegion(size)} active={size == region}>
                            {size}
                          </SizeButton>
                        ))}
                      </SizeButtonWrapper>
                    </SizeWrapper>
                  ) : null}
                </Message>
                <AccordionAnimation active={isOpen}>
                  <Grid key={option?.name?.toString() + index}>
                    {option?.values?.map(
                      (value, index) =>
                        getSize(value) && (
                          <Variant
                            key={value?.toString() + index}
                            active={selectedOptions?.find(({ name }) => name === option?.name)?.value === value ? "true" : "false"}
                            outOfStock={isOutOfStock({ name: option.name, value })}
                            onClick={() => handleVariant({ name: option.name, value })}
                          >
                            {getSize(value)}
                          </Variant>
                        )
                    )}
                  </Grid>
                </AccordionAnimation>
              </GridWrapper>
            )
          }

          if (option?.values.length > 1) {
            return (
              <StyledSelectWrapper key={index} spacing={"true"}>
                <StyledSelect
                  layout={"productVariant"}
                  value={selectedOptions?.find(({ name }) => name === option?.name)?.value}
                  onChange={({ target: { value } }) => handleVariant({ name: option.name, value })}
                >
                  <StyledSelectOption disabled value={""} selected>
                    {option?.name}
                  </StyledSelectOption>
                  {option?.values?.map((value, index) => (
                    <StyledSelectOption key={value?.toString() + index} value={value}>
                      {value}
                    </StyledSelectOption>
                  ))}
                </StyledSelect>
                <StyledInputIconWrapper>
                  <Icon name={"chevron"} className={`mb-0-8 mr-0-8`} width={"12"} height={"12"} />
                </StyledInputIconWrapper>
              </StyledSelectWrapper>
            )
          }
        })}
      </Wrapper>
    ) : null
  }
)
