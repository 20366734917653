import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withPopup } from "./withPopup"
import { Content, Prev, Next } from "./PopupStyles"
import { Popup as Modal } from "../../../Popup/Popup"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Video } from "../../../Video/Video"

SwiperCore.use([Navigation])

export const Popup = withPopup(({ active, setActive, setSwiper, media }) => (
  <Modal active={active} setActive={setActive} width={"product"} outerClose transparent>
    <Content>
      <Prev id={"desktop-prev"} title={"previous image"}>
        <Icon name={"chevronLeft"} width={16} height={28} />
      </Prev>
      <Next id={"desktop-next"} title={"next image"}>
        <Icon name={"chevronRight"} width={16} height={28} />
      </Next>
      <Swiper
        navigation={{
          prevEl: "#desktop-prev",
          nextEl: "#desktop-next",
        }}
        lazy={true}
        loop={true}
        preloadImages={false}
        onSwiper={setSwiper}
      >
        {media.map(item => (
          <SwiperSlide key={item.id}>
            {item?.image?.src ? (
              <Image image={item?.image} ratio={`1/1`} />
            ) : (
              <Video url={item?.sources?.[1]?.url} image={item?.previewImage} ratio={"1/1"} autoplay loop />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Content>
  </Modal>
))
