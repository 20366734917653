import React from "react"

import { withSizeGuide } from "./withSizeGuide"
import { Wrapper, Title, Description, Tables, Space } from "./SizeGuideStyles"
import { Table } from "./Table"
import { UnderlinedButton } from "../../Styled/Button"
import { Modal, ModalOpenButton, ModalContentsWithLayout } from "../../Modal/Modal"
import { RichText } from "../../RichText/RichText"
import tw from "twin.macro"

export const SizeGuide = withSizeGuide(({ showSizeGuide, sizeGuide }) => {
  return showSizeGuide ? (
    <Modal>
      <Wrapper>
        <ModalOpenButton>
          <UnderlinedButton noMargin={true}>
            <span>Size Guide</span>
          </UnderlinedButton>
        </ModalOpenButton>
      </Wrapper>
      <ModalContentsWithLayout layout={"sizeGuide"}>
        <Title noBottomSpace={!sizeGuide.description}>{sizeGuide.title}</Title>
        <Description bottomSpace={sizeGuide.contentAbove ? false : true}>{sizeGuide.description}</Description>
        {sizeGuide.contentAbove && <RichText css={[sizeGuide.description ? tw`py-2` : tw`pb-2`]}>{sizeGuide.contentAbove}</RichText>}
        <Tables>
          {sizeGuide?.tables?.map(table => (
            <Table sizeGuide={sizeGuide} key={table._key} chart={table.table} />
          ))}
        </Tables>
        {sizeGuide.content && (
          <Space>
            <RichText>{sizeGuide.content}</RichText>
          </Space>
        )}
      </ModalContentsWithLayout>
    </Modal>
  ) : null
})
