import React from "react"
import { withReviewsWidget } from "./withReviewsWidget"

export const ReviewsWidget = withReviewsWidget(
  ({ productId, widgetContainer }): JSX.Element => (
    <div
      style={{
        padding: "0px 8px",
      }}
      ref={widgetContainer}
      data-oke-widget
      data-oke-reviews-product-id={`shopify-${productId}`}
    ></div>
  )
)
