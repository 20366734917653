import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"
import { useQueries } from "../hooks/useQueries"

import { Product as Template } from "../components/Product/Product"
import { useApp } from "../hooks/useApp"

export const query = graphql`
  query($handle: String!) {
    product: sanityProduct(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityProductFragment
      siblings: _rawSiblings(resolveReferences: { maxDepth: 2 })
    }
    content: allSanityProduct(filter: { shopify: { shopifyHandle: { eq: $handle } } }, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          hideGender
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          related: _rawRelated(resolveReferences: { maxDepth: 3 })
          collection: _rawCollection(resolveReferences: { maxDepth: 3 })
        }
      }
    }
    template: sanityTemplateProduct {
      settingShowRelatedProducts
      settingShowInstagramFeed
      sizeConversions {
        mensSize
        womensSize
        multiRegionConversions {
          _key
          region
          mensSize
          womensSize
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => {
  const {
    helpers: { encodeShopifyId, storage },
  } = useCore()
  const {
    config: {
      settings: {
        keys,
        constraints: { productMetafields },
      },
    },
  } = useApp()
  const {
    queries: { GET_PRODUCT_COMPLETE },
  } = useQueries()

  const { useLazyQuery } = useShopify()
  const {
    product: {
      shopify: { id, handle },
      tags,
      siblings,
    },
  } = data

  const [getProduct, { data: live, called, loading, error }] = useLazyQuery(GET_PRODUCT_COMPLETE, {
    fetchPolicy: `cache-and-network`,
    nextFetchPolicy: "cache-first",
    variables: {
      id: encodeShopifyId(id, "Product"),
      handle,
      parentQuery: `tag:'${tags?.find(tag => tag.includes("style_group"))}'`,
      firstVariants: 30,
      metafieldIdentifiers: productMetafields,
      countryCode: storage.get(keys?.country),
      firstImages: 10,
      firstMedia: 1,
      firstCollections: 1,
    },
  })

  useEffect(() => {
    if (!handle || called) return
    getProduct()
  }, [handle, called, getProduct])

  if (error) console.error(error)

  console.log("data live", live)
  return (
    <Template {...props} {...data} live={live} loading={loading} sanityProduct={data?.product?.shopify?.raw} productId={id} siblings={siblings} />
  )
}
export default Component
