import React from "react"

import { withMoreInformation } from "./withMoreInformation"
import { Wrapper } from "./MoreInformationStyles"
import { Subtitle as Title } from "../ProductStyles"
import { Accordion } from "../../Accordion/Accordion"
import { RichText } from "../../RichText/RichText"

export const MoreInformation = withMoreInformation(
  ({ spec, care, moreInfo, additionalProductInformationText, additionalProductSpecText, additionalProductCareText }) =>
    spec || care || moreInfo?.length > 0 ? (
      <Wrapper>
        <Title>{additionalProductInformationText}</Title>
        {spec?.length > 0 ? (
          <Accordion layout={"info"} title={additionalProductSpecText}>
            <RichText>{spec}</RichText>
          </Accordion>
        ) : null}
        {care ? (
          <Accordion layout={"info"} title={additionalProductCareText}>
            {care}
          </Accordion>
        ) : null}
        {moreInfo?.map(info => (
          <Accordion key={info._key} layout={"info"} title={info.question}>
            <RichText>{info.answer}</RichText>
          </Accordion>
        ))}
      </Wrapper>
    ) : null
)
