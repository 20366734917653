import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useApp } from "../../../hooks/useApp"
import { useShopify } from "../../../hooks/useShopify"

export const withSizeGuide = Component => ({ name = "SizeGuide", tags }) => {
  const {
    config: {
      settings: { tags: configTags },
    },
  } = useApp()

  const { edgeNormaliser } = useShopify()

  const sizeGuideTag = tags?.find(tag => tag.includes(configTags.sizeGuide))

  const { sizeGuides } = useStaticQuery(graphql`
    query SANITY_SIZE_CHARTS {
      sizeGuides: allSanitySizeChart {
        edges {
          node {
            title
            description: _rawDescription(resolveReferences: { maxDepth: 2 })
            tables: _rawTables(resolveReferences: { maxDepth: 2 })
            contentAbove: _rawContentAbove(resolveReferences: { maxDepth: 8 })
            content: _rawContent(resolveReferences: { maxDepth: 8 })
            col1Color {
              hex
            }
            row1Color {
              hex
            }
            row2Color {
              hex
            }
            restRowsColor {
              hex
            }
          }
        }
      }
    }
  `)

  const sizeGuideHandle = sizeGuideTag ? sizeGuideTag?.split(":")?.[1] : undefined

  const defaultSizeGuide = null
  // const defaultSizeGuide = "Unisex Size Chart"

  const selectedSizeGuide = edgeNormaliser(sizeGuides)?.filter(guide => guide?.title.trim() === sizeGuideHandle)?.[0] || defaultSizeGuide

  const showSizeGuide = Boolean(selectedSizeGuide)

  Component.displayName = name
  return <Component showSizeGuide={showSizeGuide} sizeGuide={selectedSizeGuide} />
}
