import React, { useState } from "react"

export const withDesktopImages = Component => ({ name = "DesktopImages", image, loading, media }) => {
  const [clickedSlideIndex, setClickedSlideIndex] = useState(0)
  const [popupActive, setPopupActive] = useState(false)

  const handleImageClick = index => {
    setPopupActive(true)
    setClickedSlideIndex(index)
  }

  Component.displayName = name
  return (
    <Component
      image={image}
      loading={loading}
      handleImageClick={handleImageClick}
      popupActive={popupActive}
      setPopupActive={setPopupActive}
      clickedSlideIndex={clickedSlideIndex}
      media={media}
    />
  )
}
