import tw, { styled } from "twin.macro"

export const Outer = tw.div``

export const Inner = tw.div`flex flex-col md:flex-row md:py-6 md:px-3 max-w-xl mx-auto relative`

export const ImagesWrapper = tw.div`w-full md:w-1/2 mlg:w-6/10 md:flex-1`

export const DetailsWrapper = tw.div`w-full md:min-w-36 md:w-1/2 mlg:w-4/10 px-2-4 md:px-3-6`

export const Sticky = tw.div`
  md:sticky md:top-18
`

export const TitleWrapper = tw.div`
  flex mb-0-2 md:mb-0-8 mt-2-4 md:mt-3-2 items-center
`

export const Title = tw.h1`
  flex-1 font-bold text-20 md:text-28 leading-1.8 md:leading-1.28 tracking-2 uppercase
`

export const PriceWrapper = tw.div`
  flex-none mb-0-4
`

export const Subtitle = tw.h3`
  font-bold text-16 leading-1.25 tracking-4 uppercase mb-3-2
`

export const BadgeWrapper = tw.div`
  flex flex-row items-center
`

export const Divider = styled.hr<DividerProps>`
  ${tw`text-grey`}
  ${({ layout }) => {
    if (layout === "desktop") return tw`hidden md:block`
    if (layout === "mobile") return tw`md:hidden`
  }}
`

export const VariantsWrapper = tw.div`
  flex flex-col mb-1-8
`

type DividerProps = {
  layout?: "mobile" | "desktop"
}
