import React from "react"

import { useShopify } from "../../../hooks/useShopify"

export const withImages = Component => ({ name = "Images", image: originalImage, media, loading }) => {
  const { imageNormaliser } = useShopify()

  const image = imageNormaliser({ src: originalImage }, 1500)

  const filteredMedia = media
    .filter(item => !item?.image?.originalSrc?.includes("COVER"))
    .map(item => {
      if (item.mediaContentType === "IMAGE") {
        return {
          ...item,
          image: imageNormaliser(item.image, 1500),
        }
      } else {
        return {
          ...item,
          previewImage: imageNormaliser(item.previewImage, 1500),
        }
      }
    })

  Component.displayName = name
  return <Component image={image} loading={loading} media={filteredMedia} />
}
