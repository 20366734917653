import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { getVariantBySelectedOptions } from "../../../hooks/useShopify"
import { useCore } from "../../../hooks/useCore"

export const withVariants = Component => ({
  name = "Variants",
  handleVariant,
  product,
  selectedOptions,
  selectedVariant,
  sizeConversions,
  gender,
  hideGender,
}) => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    config: {
      settings: {
        constraints: { SIZE },
      },
    },
  } = useApp()
  const [region, updateRegion] = useState(storage.get("size-region") || "AU")

  const { productTemplateData } = useStaticQuery(graphql`
    query {
      productTemplateData: sanityTemplateProduct {
        additionalSizePrefix
        additionalSizeSelectionText
      }
    }
  `)

  const { additionalSizePrefix, additionalSizeSelectionText } = productTemplateData || {}

  const [isOpen, setIsOpen] = useState(true)

  const isOutOfStock = option => {
    const variantSelectedOptions = [...selectedOptions.filter(selectedOption => selectedOption.name !== option.name), option]
    const variant = getVariantBySelectedOptions(variantSelectedOptions, product?.variants)

    return !variant?.availableForSale
  }

  const sizeRegions = useMemo(() => ["AU", ...new Set(sizeConversions?.map(sc => sc.multiRegionConversions?.map(mrc => mrc.region))?.flat(1))], [
    sizeConversions,
  ])

  const getMultiRegionSize = (size, gender) => {
    const isWomens =
      gender === "Womens" ||
      (product?.tags?.includes("gender:Womens") && !(product?.tags?.includes("gender:Mens") || product?.tags?.includes("gender:Unisex")))
    if (region !== "AU") {
      const sizeConversion = sizeConversions?.find(sizeConversion => (isWomens ? sizeConversion.womensSize == size : sizeConversion.mensSize == size))
      const multiRegionConversion = sizeConversion?.multiRegionConversions?.find(mrc => mrc.region == region)
      if (gender === "m") {
        return multiRegionConversion?.mensSize
      } else {
        return multiRegionConversion?.womensSize
      }
    }
    return gender === "m"
      ? size
      : sizeConversions?.find(sizeConversion => (isWomens ? sizeConversion.womensSize == size : sizeConversion.mensSize == size))?.womensSize
  }

  const getSize = size => {
    if (gender && sizeConversions && !hideGender) {
      if (gender === "Unisex") {
        const womensSize = sizeConversions.find(sc => sc.mensSize == size)?.womensSize
        return womensSize ? `M ${getMultiRegionSize(size, "m")} | W ${getMultiRegionSize(size, "w")}` : `M ${getMultiRegionSize(size, "m")}`
      }

      if (gender === "Womens") {
        if (getMultiRegionSize(size, "w")) return `${gender} ${getMultiRegionSize(size, "w")}`
        else return null
      }

      return `${gender} ${getMultiRegionSize(size, "m")}`
    }
    return size
  }

  const setRegion = (region: string) => {
    updateRegion(region)
    storage.set("size-region", region)
  }

  Component.displayName = name
  return (
    <Component
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleVariant={handleVariant}
      options={product?.options}
      product={product}
      selectedOptions={selectedOptions}
      SIZE={SIZE}
      additionalSizePrefix={region ? `${region} size` : additionalSizePrefix}
      additionalSizeSelectionText={additionalSizeSelectionText}
      isOutOfStock={isOutOfStock}
      gender={gender}
      sizeConversions={sizeConversions}
      sizeRegions={sizeRegions}
      getSize={getSize}
      hideGender={hideGender}
      region={region}
      updateRegion={setRegion}
    />
  )
}
